<template>
  <b-container fluid class="p-0">
    <b-row class="d-flex">
      <b-col md="4" class="text-left mx-">
        <slot name="field_label_left">Keys</slot>
      </b-col>
      <b-col md="4" class="text-left mx-4">
        <slot name="field_label_right">Values:</slot>
      </b-col>
    </b-row>
    <b-row v-for="(item, index) in list" :key="getID(index)" class="form-repeater no-gutters">
      
      <b-col cols="4" class="m-0 p-0 float-left">
        <custom-input
          :key="uuidv4()"
          size="sm"
          v-model="list[index][value1]"
          horizontal
          :advanced="false"
          :possibleValues="possibleValues"
          disableSourceRecommend
        />

      </b-col>
      <span class="mr-2 ml-2 align-self-center mb-2">: </span>
      <b-col cols="6">
        <custom-input
          size="sm"
          :key="uuidv4()"
          v-model="list[index][value2]"
          horizontal
          :advanced="false"
          :possibleValues="possibleValues"
          disableSourceRecommend
        />

      </b-col>
      <b-col cols="1" class="text-right align-self-center mb-2">
        <b-button
          v-if="allow_delete"
          class="mt-50"
          size="sm"
          variant="outline-danger"
          @click="deleteItem(index)"
        >
          <feather-icon
            icon="XIcon"
          />
        </b-button>
      </b-col>
      
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button :variant="!button ? 'none' : 'outline-primary'" class="button-add px-75 py-50" @click="addItem">{{ addButtonText }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCol, 
  BButton, 
  BFormInput, 
  BInputGroup, 
} from 'bootstrap-vue'
import CustomInput from '@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue'
import { v4 as uuidv4 } from 'uuid'
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';
import NormalizedAgent from '@/custom/class/NormalizedAgent.js';
import { isObject } from '@vueuse/shared';
import { layout } from 'echarts/lib/layout/barGrid';
import { t } from '@/@core/libs/i18n/utils';

  export default {
    components: {
      BContainer, 
      BRow, 
      BCol, 
      BButton, 
      BFormInput, 
      BInputGroup,
      CustomInput,
      HelperTooltip
    },
    props: {
      value: {
        type: [Array , String],
        default: () => []
      },
      addButtonText: {
        type: String,
        default: '+ add new' 
      },
      dropZone: {
        type: Boolean,
        default: false,
      },
      possibleValues: {
        type: Object,
        default: undefined
      },
      defaultValue: {
        type: [ NormalizedAgent, Object ],
        required: true
      },
      button: {
        type: Boolean,
        default: false,
      },
      allow_delete: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        uuidv4,
        list : [],
        value1: undefined,
        value2: undefined,
      }
    },
    computed: {
      items: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },

    },
    mounted(){
      this.$emit('listMounted')
      
      
      this.$nextTick(() => {
        this.value1 = Object.keys(this.defaultValue)[0];
        this.value2 = Object.keys(this.defaultValue)[1];
        this.list = this.makeInputMap(this.items);
      });


      },
    methods: {
      addItem() {
        this.list.push(structuredClone(this.defaultValue));
      },
      deleteItem(key) {
        if (this.list[key].id) {
          this.$emit('deleteItem', { "item": this.list[key], "index": key });
        }
        this.list.splice(key, 1);
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      makeOutputMap(array){
        let result = []      
        array.forEach(layout => {
        let layoutMap = {}
          layoutMap[layout[this.value1]] = layout[this.value2]
          result.push(layoutMap)
        });
        return JSON.stringify(result);
        },

      makeInputMap(value){
        if (value.length == 0){
          return [];
        }
        let array
        if (!Array.isArray(value)){
          array = JSON.parse((value))
        } else {
          array = value;
        }
        let result = []
        array.forEach(item => {
          let key = Object.keys(item)[0]
          let value = Object.values(item)[0]
          let map = {};
          map[this.value1] = key
          map[this.value2] = value
          result.push(map)
        });
        value = result

        return result;
      },

    resolveList(){ //this must be called by 'ref' through parent before using results
      return this.makeOutputMap(this.list);
    }


    }
  }
</script>

<style lang="scss" scoped>

.form-repeater{
  transition: all 0.5s ease-in-out;
}
.button-add:hover {
  color: #12e066;
}
</style>